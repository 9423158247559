type Props = {
  texts: object | String | Array<String>;
  variables: object;
};
const replaceVariable = ({ texts, variables }: Props) => {
  let stringifiedTexts = JSON.stringify(texts);
  for (let variable in variables) {
    stringifiedTexts = stringifiedTexts.replaceAll(
      `{{${variable}}}`,
      variables[variable]
    );
  }
  return JSON.parse(stringifiedTexts);
};

export default replaceVariable;
