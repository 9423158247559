import React, { Fragment } from 'react';
import { Box } from 'theme-ui';
import { nanoid } from 'nanoid';
import styled from '@emotion/styled';

const SignInvitation = styled.div`
  max-width: 450px;
  width: 80%;
  box-shadow: 0px 30px 40px 0px rgb(0 0 0 / 20%);
  text-align: center;
  display: grid;
  justify-items: center;
  margin: 10px 0 40px;
  padding: 2rem 1rem;
  p {
    color: #282828;
    font-size: 1.25em;
    width: 100%;
    font-weight: 600;
    margin: 0;
    margin-bottom: 1rem;
  }
  a {
    background-color: #6910fb;
    border-radius: 99px;
    color: white;
    box-shadow: 0 1px 3px -2px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    display: flex;
    height: 45px;
    justify-content: center;
    align-items: center;
    width: 250px;
    text-decoration: none;
  }
`;

const styles = {
  container: {
    display: `flex`,
    justifyContent: `center`,
  },
};

export default function ConvertTextToQuestionsComponent({
  content,
  marginX,
  marginY,
}) {
  return processText(content, marginX, marginY);
}
const processText = (content, marginX, marginY) => {
  return (
    <div>
      {content.map(({ link, type, value, child }) => {
        return (
          <Box key={nanoid()} px={marginX || 0} py={marginY || 0}>
            {['invitation', 'dot', 'number'].includes(type) ? (
              type === 'invitation' ? (
                renderInvitation({ value, child })
              ) : (
                <div></div>
              )
            ) : (
              <Fragment>
                {renderText({ value, type })}
                {child && processText(child, marginX, marginY)}
              </Fragment>
            )}
          </Box>
        );
      })}
    </div>
  );
};

const renderInvitation = ({ value, child }) => {
  return (
    <div style={styles.container}>
      <SignInvitation>
        <p>{value}</p>
        <a href={child[0].value}>{child[1].value}</a>
      </SignInvitation>
    </div>
  );
};
const renderText = ({ value, type }) => {
  return value?.map((item) => {
    const newValue = decodeText(item);
    const htmlText = newValue && convertToText(newValue);
    return wrapText({ type, htmlText });
  });
};
// this functions convert "type" to html tags
const wrapText = ({ type, htmlText }) => {
  switch (type) {
    case 'h1':
      return <h1>{htmlText}</h1>;
    case 'h2':
      return <h2>{htmlText}</h2>;
    case 'h3':
      return <h3>{htmlText}</h3>;
    case 'h4':
      return <h4>{htmlText}</h4>;
    case 'h5':
      return <h5>{htmlText}</h5>;
    case 'h6':
      return <h6>{htmlText}</h6>;
    case 'dot':
    case 'number':
      return <li>{htmlText}</li>;
    case 'bold':
      return <strong>{htmlText}</strong>;
    default:
      return htmlText;
  }
};

// this function converts inner styles to an object
// strong|this part will be bold
// link(www.togetherprice.com)|this part will be a link with href="http://www.togetherprice.com"
// more choice can be added
const decodeText = (text: string) => {
  const index = text.indexOf('|');
  if (index !== -1) {
    const values = text.split('|');
    if (values[0].substring(0, 4) === 'link') {
      const link = values[0].substring(
        (values[0]?.indexOf('(') || 0) + 1,
        values[0].indexOf(')') || values[0].length
      );
      return { type: 'link', href: link, value: values[1] };
    } else if (values[0].substring(0, 6) === 'strong') {
      return { type: 'strong', value: values[1] };
    } else if (values[0].substring(0, 4) === 'line') {
      return { type: 'line', value: values[1] };
    }
  } else {
    return { type: 'text', value: text };
  }
};

// this function converts objects to html tags
const convertToText = ({
  type,
  value,
  href,
}: {
  type: string;
  value: string;
  href?: string;
}) => {
  switch (type) {
    case 'text':
      return <span>{value} </span>;
    case 'link':
      return (
        <a
          style={{ color: 'blue' }}
          href={href}
          target='_blank'
          rel='noopener noreferrer'
        >
          {value}{' '}
        </a>
      );
    case 'line':
      return (
        <>
          <br style={{ marginBottom: '1rem' }} />
          <span>{value} </span>
        </>
      );
    case 'strong':
      return <strong>{value} </strong>;
  }
};
